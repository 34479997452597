import * as React from "react";
import classNames from "classnames";

//components
import IconText from "./iconText";
import TitleSeparator from "./titleSeparator";

class textColsImgBackground extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <div
        className={classNames(
          "textColdImgBackgroundContent",
          "backgroundImg",
          "d-flex",
          "position-relative",
          "align-items-center",
          "pb-5",
          "pb-sm-0",
          "pb-md-0",
          "pb-lg-0",
          {
            bottomDiagonal: info.bottomDiagonal,
          }
        )}
      >
        <img className="imgBackCenter pb-50 pb-sm-200" src={info.image.src} alt={info.image.alt} />
        {info.filter && <div className="filterLight"></div>}
        <div
          className={classNames("textTop", "notFull65", "pt-sm-50", {
            "text-center": info.textCenter,
          })}
        >
          <TitleSeparator info={info}></TitleSeparator>
          <div
            className={
              "pt-50 pt-sm-10 row row-cols-" + info.cols.length / 2 + " row-cols-sm-" + info.cols.length / 2 + " row-cols-md-" + info.cols.length
            }
          >
            {info.cols.map((col, i) => (
              <IconText key={i} info={col}></IconText>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default textColsImgBackground;
