import * as React from "react";
import classNames from "classnames";

import ButtonLink from "../components/_partials/_buttonLink";

class ButtonSeparator extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <div className="text-center">
        <div
          className={classNames("buttonSeparatorContent", "height-80px", {
            topDiagonal: info.topDiagonal,
            backgroundGrey: info.backgroundGrey,
          })}
        ></div>
        <div className="position-absolute w-100 pushTopButton">
            <ButtonLink url={info.cta.url} type={info.cta.type} extraClass="position-relative font14" text={info.cta.text} icon={info.cta.icon}></ButtonLink>
        </div>
      </div>
    );
  }
}

export default ButtonSeparator;
