/**
 * Foursource page
 * https://foursource.com/
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

// page content
import { pageTexts } from "../content/index";
import { associates } from "../content/pages/associatesPrincipal";

// images
import LogosImg from "../images/sliderlogos_2-1.png";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import LogoSlide from "../components/logoSlide";
import TextColsImgBackground from "../components/textColsImgBackground";
import ButtonSeparator from "../components/buttonSeparator";
import BulletsSection from "../components/bulletsSection";
import Associates from "../components/_page/associates";

const MOBILE_WIDTH = 992;
class indexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: (typeof window !== "undefined") ? (window.innerWidth <= MOBILE_WIDTH ? true : false):'',
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.associates = associates(this.t);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };

  render() {
    return (
      <>
        <Layout pageName="meta" whiteMenu={false}>
          <TextImgBackgroundFull info={this.state.mobile ? this.text.topInfoMobile : this.text.topInfo}></TextImgBackgroundFull>
          <LogoSlide img={LogosImg} duration="18"></LogoSlide>
          <TextColsImgBackground info={this.text.mapInfo}></TextColsImgBackground>
          <ButtonSeparator info={this.text.requestDemo}></ButtonSeparator>
          <div className="twoColsContent bottomDiagonal backgroundGrey paddingSidesBig">
            <div className="pt-75 row row-cols-md-2 row-cols-sm-1 m-0 pb-100">
              {this.text.twoCols.map((col, i) => (
                <BulletsSection key={i} info={col} num={i}></BulletsSection>
              ))}
            </div>
          </div>
          <Associates info={this.associates.cardsInfo}></Associates>
        </Layout>
      </>
    );
  }
}

export default withTrans(indexPage);