import * as React from "react";
import "../style/components/logoSlide.scss";

class LogoSlide extends React.Component {

  render() {

    const {img, duration} = this.props;

    return (
      <div className="logoSliderContent pt-2 d-flex">
        <div
          className="logoSlider w-100 position-relative"
          style={{
            backgroundImage: `url(${img})`,
            animationDuration: `${duration}s`,
          }}
        ></div>
      </div>
    );
  }
}

export default LogoSlide;
