import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import CountriesIcon from "../images/icon/countriesIcon.svg";
import CompaniesIcon from "../images/icon/companiesIcon.svg";
import SamplesIcon from "../images/icon/samplesIcon.svg";
import BusinessIcon from "../images/icon/handshake-icon.svg";
import TopBackground from "../images/1-homepage.jpeg";
import TopBackgroundMobile from "../images/headersMobile/1-homepage-mobile.jpg";
import WorldMapImg from "../images/worldmap.png";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("homepage.welcomeTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("homepage.welcomeSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("homepage.ctaButton"),
        url:
          process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("homepage.imgAlt"),
      },
    },
    topInfoMobile: {
      title: {
        text: t("homepage.welcomeTitle"),
        colorClass: "colorLight",
        extraClass: "shadowEffect",
      },
      subtitle: {
        text: t("homepage.welcomeSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("homepage.ctaButton"),
        url:
          process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackgroundMobile,
        alt: t("homepage.imgAlt"),
      },
    },
    mapInfo: {
      title: {
        text: t("homepage.smartSourcing"),
        colorClass: "colorDark",
      },
      subtitle: {
        text: t("homepage.reactChanging"),
        colorClass: "colorDark",
      },
      textCenter: true,
      separator: false,
      image: {
        src: WorldMapImg,
        alt: t("homepage.map.worldMapAlt"),
      },
      bottomDiagonal: false,
      filter: true,
      cols: [
        {
          title: {
            text: t("homepage.map.countriesNum"),
            textBefore: " >",
            isNum: true,
            colorClass: "colorMain",
          },
          subtitle: {
            text: t("homepage.map.countries"),
            colorClass: "colorDark",
          },
          icon: {
            src: CountriesIcon,
            alt: t("homepage.map.countriesIconAlt"),
          },
        },
        {
          title: {
            text: t("homepage.map.companiesNum"),
            textBefore: " >",
            isNum: true,
            colorClass: "colorMain",
          },
          subtitle: {
            text: t("homepage.map.companies"),
            colorClass: "colorDark",
          },
          icon: {
            src: CompaniesIcon,
            alt: t("homepage.map.companiesIconAlt"),
          },
        },
        {
          title: {
            text: t("homepage.map.samplesNum"),
            textBefore: " >",
            isNum: true,
            colorClass: "colorMain",
          },
          subtitle: {
            text: t("homepage.map.samples"),
            colorClass: "colorDark",
          },
          icon: {
            src: SamplesIcon,
            alt: t("homepage.map.samplesIconAlt"),
          },
        },
        {
          title: {
            text: t("homepage.map.businessNum"),
            textAfter: " s",
            isNum: true,
            colorClass: "colorMain",
          },
          subtitle: {
            text: t("homepage.map.business"),
            colorClass: "colorDark",
          },
          icon: {
            src: BusinessIcon,
            alt: t("homepage.map.businessIconAlt"),
          },
        },
      ],
    },
    requestDemo: {
      cta: {
        text: t("meta.demoRequestText"),
        url: t("meta.demoDeskLink"),
        extraClass: "bigWidth",
        type: "whiteColor",
      },
      topDiagonal: true,
      backgroundGrey: "backgroundGrey",
    },
    twoCols: [
      {
        title: {
          text: t("homepage.buyerFabris"),
          colorClass: "colorDark",
        },
        subtitle: {
          text: t("homepage.gainWorldwide"),
          colorClass: "colorDark",
        },
        icon: {
          fontAwesome: false,
          src: CheckIcon,
          alt: t("homepage.checkAlt"),
        },
        separator: true,
        item: {
          text: [
            t("homepage.findSuppliers"),
            t("homepage.manageSuppliers"),
            t("homepage.exploreShowrooms"),
            t("homepage.receiveQuotes"),
            t("homepage.monitorCerts"),
          ],
          colorClass: "colorDark",
        },
        cta: {
          text: t("homepage.learnMore"),
          url: "/buyers/",
          type: "mainColor",
          extraClass: "bigWidth",
          parentClass: "prButton",
        },
      },
      {
        title: {
          text: t("homepage.fabricSuppliers"),
          colorClass: "colorDark",
        },
        subtitle: {
          text: t("homepage.foursourceTool"),
          colorClass: "colorDark",
        },
        icon: {
          fontAwesome: false,
          src: CheckIcon,
          alt: t("homepage.checkAlt"),
        },
        separator: true,
        item: {
          text: [
            t("homepage.gainAccess"),
            t("homepage.directTouch"),
            t("homepage.spotOpportunities"),
            t("homepage.beDiscover"),
            t("homepage.showcaseCompany"),
          ],
          colorClass: "colorDark",
        },
        cta: {
          text: t("homepage.learnMore"),
          url: "/apparel-manufacturers/",
          type: "mainColor",
          extraClass: "bigWidth",
          parentClass: "prButton",
        },
      },
    ],
  };
};
